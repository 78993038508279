import { REVIVE } from '../../actions/revive/types';

export default function (state = false, action) {
  switch (action.type) {
    case REVIVE:
      return !state;
    default:
      return state;
  }
}
