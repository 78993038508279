import { SET_ROLE, CLIENT_ROLE } from '../../actions/roles/types';

const initialState = { role: CLIENT_ROLE };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ROLE:
      return { ...state, role: payload };

    default:
      return state;
  }
}
