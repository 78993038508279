import { types } from '../../actions/fileservice/types';

export const initialState = {
  tokenType: null,
  tuningTarget: null,
  vehicleBrand: {},
  vehicleModel: {},
  vehicleModelGeneration: {},
  vehicleEngine: {},
  vehicleEcu: {},
  vehicleTcu: {},
  vehicleHp: null,
  vehicleKw: null,
  vehicleYear: '',
  vehicleGearbox: {},
  vehicleLicensePlate: '',
  vehicleVIN: '',
  vehicleFuelOctaneRating: null,
  vehicleHardwareNumber: '',
  vehicleSoftwareNumber: '',
  readMethod: {},
  tuningType: {},
  tuningOptions: [],
  timeFrame: '',
  onDyno: false,
  additionalEmail: '',
  additionalComment: '',
  customVehicleData: [],
  carMileage: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.RESET_FORM: {
      return initialState;
    }
    case types.SELECT_DEVICE_TYPE: {
      return { ...state, tokenType: payload };
    }
    case types.SELECT_TARGET: {
      return { ...state, tuningTarget: payload };
    }
    case types.ADD_BRAND: {
      return { ...state, vehicleBrand: payload };
    }
    case types.ADD_MODEL: {
      return { ...state, vehicleModel: payload };
    }
    case types.ADD_GENERATION: {
      return { ...state, vehicleModelGeneration: payload };
    }
    case types.ADD_ENGINE: {
      return { ...state, vehicleEngine: payload };
    }
    case types.ADD_TCU: {
      return { ...state, vehicleTcu: payload };
    }
    case types.ADD_ECU: {
      return { ...state, vehicleEcu: payload };
    }
    case types.ADD_HP: {
      return { ...state, vehicleHp: payload };
    }
    case types.ADD_KW: {
      return { ...state, vehicleKw: payload };
    }
    case types.ADD_YEAR: {
      return { ...state, vehicleYear: payload };
    }
    case types.ADD_GEARBOX: {
      return { ...state, vehicleGearbox: payload };
    }
    case types.ADD_LICENSE_PLATE: {
      return { ...state, vehicleLicensePlate: payload };
    }
    case types.ADD_VIN: {
      return { ...state, vehicleVIN: payload };
    }
    case types.ADD_OCTANE_RATING: {
      return { ...state, vehicleFuelOctaneRating: payload };
    }
    case types.ADD_HARDWARE_NUMBER: {
      return { ...state, vehicleHardwareNumber: payload };
    }
    case types.ADD_SOFTWARE_NUMBER: {
      return { ...state, vehicleSoftwareNumber: payload };
    }
    case types.ADD_READ_METHOD: {
      return { ...state, readMethod: payload };
    }
    case types.ADD_TUNING_TYPE: {
      return { ...state, tuningType: payload };
    }
    case types.ADD_TUNING_OPTION: {
      return { ...state, tuningOptions: payload };
    }
    case types.ADD_TIMEFRAME: {
      return { ...state, timeFrame: payload };
    }
    case types.SELECT_ONDYNO: {
      return { ...state, onDyno: payload };
    }
    case types.ADD_ADDITIONAL_EMAIL: {
      return { ...state, additionalEmail: payload };
    }
    case types.ADD_ADDITIONAL_COMMENT: {
      return { ...state, customVehicleData: payload };
    }
    case types.ADD_CAR_MILEAGE: {
      return { ...state, carMileage: payload };
    }
    default:
      return state;
  }
}
