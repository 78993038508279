import {
  SET_NOTIFICATION,
  SET_DEVICE_TOKEN,
  DELETE_DEVICE_TOKEN,
} from '../../actions/firebase/types';

const initialState = {
  firebasNotification: false,
  deviceToken: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIFICATION:
      return { ...state, firebasNotification: payload };

    case SET_DEVICE_TOKEN:
      return { ...state, deviceToken: payload };

    case DELETE_DEVICE_TOKEN:
      return { ...state, deviceToken: null };

    default:
      return state;
  }
}
