import { combineReducers } from 'redux';

import auth from './auth/auth';
import message from './auth/message';
import roles from './roles/roles';
import general from './general/general';
import errors from './errors/errors';
import checkout from './checkout/checkout';
import fileservice from './fileservice/fileservice';
import mercure from './mercure/mercure';
import revive from './revive/revive';
import firebase from './firebase/firebase';

export default combineReducers({
  auth,
  message,
  roles,
  general,
  errors,
  checkout,
  fileservice,
  mercure,
  revive,
  firebase,
});
