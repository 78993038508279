import { types } from '../../actions/mercure/types';
import { eventTypes } from 'containers/common/liveServer/eventTypes';
import general from '../general/general';

const localEvents =
  localStorage.getItem(`tc.live.${sessionStorage.getItem('tc.id')}`) &&
  Object.entries(
    JSON.parse(
      localStorage.getItem(`tc.live.${sessionStorage.getItem('tc.id')}`)
    )
  );

const initialStateFromLocalStorage = localEvents?.reduce((object, item) => {
  return { ...object, [item[0]]: item[1] };
}, {});

const initialState = initialStateFromLocalStorage || {
  [eventTypes.FILE_SERVICE]: {},
  [eventTypes.ORDER]: {},
  [eventTypes.SUPPORT_TICKET]: {},
  [eventTypes.SUPPORT_MESSAGE]: {},
  [eventTypes.FILE_SERVICE_REMARKS]: {},
  lastEventId: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log(
  //   '\x1b[34m' + '======= the payload again ======= \x1b[0m' + '\ntype:',
  //   type
  // );
  // console.log(
  //   '\x1b[35m' + '\n----------- payload -----------\n' + '\x1b[0m',
  //   type, payload
  // );

  const maxNumOfEntity = 200;
  switch (type) {
    case types.ADD_FS_EVENT:
    case types.ADD_ST_EVENT: {
      const context = payload.event['@context'];
      const eventId = payload.event['@id'];

      if(_.size(state[context]) > maxNumOfEntity) {
        state[context] = state[context].slice(10);
      }

      const newState = {
        ...state,
        [context]: { ...state[context], [eventId]: { ['@id']: eventId } },
        lastEventId: payload.lastEventId,
      };
      localStorage.setItem(
        `tc.live.${sessionStorage.getItem('tc.id')}`,
        JSON.stringify(newState)
      );
      return newState;
    }

    case types.ADD_ORDER_EVENT: {
      const context = payload.event['@context'];
      const eventId = payload.event['@id'];

      if(_.size(state[context]) > maxNumOfEntity) {
        state[context] = state[context].slice(10);
      }

      const newState = {
        ...state,
        [context]: {
          ...state[context],
          [eventId]: {
            ['@id']: eventId,
            ...payload.event.data
          }
        },
        lastEventId: payload.lastEventId,
      };
      localStorage.setItem(
        `tc.live.${sessionStorage.getItem('tc.id')}`,
        JSON.stringify(newState)
      );
      return newState;
    }

    case types.ADD_REMARK_EVENT: {
      const context = payload.event['@context'];
      const eventId = payload.event['@id'];

      if(_.size(state[context]) > maxNumOfEntity) {
        state[context] = state[context].slice(10);
      }

      const newState = {
        ...state,
        [context]: {
          ...state[context],
          [eventId]: {
            ['@id']: eventId,
            fileService: payload.event?.data?.fileService,
          },
        },
        lastEventId: payload.lastEventId,
      };
      localStorage.setItem(
        `tc.live.${sessionStorage.getItem('tc.id')}`,
        JSON.stringify(newState)
      );
      return newState;
    }

    case types.UPDATE_FS_EVENT: {
      const context = payload.event['@context'];
      const eventId = payload.event['@id'];
      const eventToUpdate = state[context][eventId];
      const isUpdateType = payload.event?.type === 'update';
      const keysToChange = isUpdateType && Object.keys(payload.event?.changes);
      const updatedEvent =
        eventToUpdate &&
        keysToChange.reduce((event, key) => {
          const val = payload.event?.changes?.[key][1] || payload[key];
          return { ...event, [key]: val };
        }, eventToUpdate);

      if(_.size(state[context]) > maxNumOfEntity) {
        state[context] = state[context].slice(10);
      }

      const newState = {
        ...state,
        [context]: {
          ...state[context],
          [eventId]: { ...updatedEvent, ['@id']: eventId },
        },
        lastEventId: payload.lastEventId,
      };

      localStorage.setItem(
        'tc.live.' + sessionStorage.getItem('tc.id'),
        JSON.stringify(newState)
      );
      return newState;
    }

    case types.UPDATE_ORDER_EVENT:
    case types.UPDATE_ST_EVENT: {
      const context = payload.event['@context'];
      const eventId = payload.event['@id'];
      const eventToUpdate = state[context][eventId];

      const isUpdateType = payload.event?.type === 'update';
      const keysToChange = isUpdateType && Object.keys(payload.event?.changes);

      const updatedEvent =
        eventToUpdate &&
        keysToChange.reduce((event, key) => {
          const val = payload.event?.changes?.[key][1] || payload[key];
          return { ...event, [key]: val };
        }, eventToUpdate);

      const newState = {
        ...state,
        [context]: {
          ...state[context],
          [eventId]: { ...updatedEvent, isNew: true },
        },
        lastEventId: payload.lastEventId,
      };
      localStorage.setItem(
        'tc.live.' + sessionStorage.getItem('tc.id'),
        JSON.stringify(newState)
      );
      return newState;
    }

    case types.REMOVE_FS_EVENT:
    case types.REMOVE_ST_EVENT: {
      const generalContext =
        type === types.REMOVE_ST_EVENT
          ? eventTypes.SUPPORT_TICKET
          : type === types.REMOVE_FS_EVENT
            ? eventTypes.FILE_SERVICE
            : null;
      if (payload) {
        const context = generalContext;
        const eventId = payload?.['@id'];
        const newContext = state[context];
        delete newContext[eventId];
        const newState = {
          ...state,
          [context]: newContext,
        };
        localStorage.setItem(
          'tc.live.' + sessionStorage.getItem('tc.id'),
          JSON.stringify(newState)
        );
        return newState;
      }
    }

    case types.REMOVE_ORDER_EVENT: {
      if (payload) {
        const context = eventTypes.ORDER;
        const eventId = payload?.['@id'];
        const newContext = state[context];
        if (newContext[eventId]) delete newContext[eventId];
        const newState = {
          ...state,
          [context]: newContext,
        };

        localStorage.setItem(
          'tc.live.' + sessionStorage.getItem('tc.id'),
          JSON.stringify(newState)
        );
        return newState;
      }
    }

    case types.REMOVE_REMARK_EVENT: {
      if (payload) {
        const context = eventTypes.FILE_SERVICE_REMARKS;
        const newContext = state[context];
        const found = Object.values(newContext).find(
          item => item.fileService === payload?.['@id']
        );
        // console.log('foundski: \n', newContext, '\n', newContext[found['@id']]);
        if (found && newContext[found['@id']]) delete newContext[found['@id']];
        const newState = {
          ...state,
          [context]: newContext,
        };

        localStorage.setItem(
          `tc.live.${sessionStorage.getItem('tc.id')}`,
          JSON.stringify(newState)
        );
        return newState;
      }
    }

    case types.ADD_STMSG_EVENT: {
      const context = payload?.event?.['@context'];
      const eventId = payload?.event?.['@id'];

      if(_.size(state[context]) > maxNumOfEntity) {
        state[context] = state[context].slice(10);
      }

      const newState = {
        ...state,
        [context]: { ...state[context], [eventId]: payload.event },
        lastEventId: payload.lastEventId,
      };

      localStorage.setItem(
        'tc.live.' + sessionStorage.getItem('tc.id'),
        JSON.stringify(newState)
      );
      return newState;
    }

    case types.UPDATE_STMSG_EVENT: {
      const context = payload?.event?.['@context'];
      const eventId = payload?.event?.['@id'];
      const isUpdateType = payload.event?.type === 'update';
      const keysToUpdate = isUpdateType && Object.keys(payload.event?.changes);
      const updatedEvent = keysToUpdate.reduce((event, key) => {
        const val = payload.event?.changes?.[key][1];
        return { ...event, [key]: val };
      }, payload.event);
      const newState = {
        ...state,
        [context]: { ...state[context], [eventId]: updatedEvent },
        lastEventId: payload.lastEventId,
      };
      localStorage.setItem(
        'tc.live.' + sessionStorage.getItem('tc.id'),
        JSON.stringify(newState)
      );
      return newState;
    }

    case types.REMOVE_STMSG_EVENT: {
      const context = eventTypes.SUPPORT_MESSAGE;
      const supportMessages = state[context];
      if (supportMessages[payload['@id']])
        delete supportMessages[payload['@id']];
      const newState = { ...state, [context]: supportMessages || {} };
      localStorage.setItem(
        'tc.live.' + sessionStorage.getItem('tc.id'),
        JSON.stringify(newState)
      );
      return newState;
    }

    case types.COUNT_EVENTS: {
      return Object.keys(state[payload]).length || 0;
    }
    default:
      return state;
  }
}
