import React from 'react';
import { useLocation } from 'react-router-dom';
import useCurrentSite from 'hooks/useCurrentSite';
import { Helmet } from 'react-helmet';

const HelmetData = () => {
  const currentInstance = useCurrentSite();
  const location = useLocation();
  const currentSiteArr = location[Object.keys(location)[0]]
    .slice(1)
    .replace(/-/g, ' ')
    .split(' ');
  const currentSite = currentSiteArr
    .map(val => {
      return val[0]?.toUpperCase() + val.slice(1);
    })
    .join(' ');

  const brands = [
    {
      instance: 1,
      favicon: 'favicon-tc.png',
      tradeName: 'TC-Performance',
      site: ` - ${currentSite}`,
    },
    {
      instance: 2,
      favicon: 'favicon-bc.png',
      tradeName: 'BEST-CHIPTUNINGFILES',
      site: ` - ${currentSite}`,
    },
    {
      instance: 3,
      favicon: 'favicon-dyno.png',
      tradeName: 'dyno-fileservice.com',
      site: ` - ${currentSite}`,
    },
  ];

  const brand = brands.find(val => {
    return currentInstance.id == val.instance;
  });

  let favicon = '';
  let tradeName = '';
  let site = '';
  if (brand) {
    favicon = Object.values(brand)[1];
    tradeName = Object.values(brand)[2];
    site = Object.values(brand)[3];
  }
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="icon" type="image/png" href={brand && favicon} sizes="16x16" />
      <title>{brand && `${tradeName} ${site}`}</title>
    </Helmet>
  );
};

export default HelmetData;
