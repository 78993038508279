import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from '../redux/reducers';

const middleware = [thunk];

const composeEnhancers = composeWithDevTools({ trace: true });

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
