import { cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAlert, CButton } from '@coreui/react';
import { useTranslation } from 'react-i18next';

const SentryBoundaryFallback = ({ resetError }) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 h-100 p-3 d-flex flex-column align-items-center justify-content-center">
      <div>
        <CAlert color="danger">
          <h5 className="d-flex mt-2 mb-3">
            <CIcon content={cilWarning} className="mt-1 mr-2" />
            <span>{t('general.error.heading')}</span>
          </h5>
          <span>{t('general.error.body')}</span>
        </CAlert>
        <div className="d-flex flex-column align-items-center">
          <p style={{ whiteSpace: 'pre-line' }} className="my-2 text-center">{t('general.error.tips')}</p>
          <span>
            {t('general.error.instructions')}:
            {' '}<a href="https://support.google.com/accounts/answer/32050">Google Chrome</a>,
            {' '}<a href="https://support.mozilla.org/kb/how-clear-firefox-cache">Mozilla Firefox</a>
          </span>
          <CButton onClick={resetError} color="primary" className="mt-3">{t('general.reload')}</CButton>
        </div>
      </div>
    </div>
  );
};

export default SentryBoundaryFallback;