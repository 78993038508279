import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './utils/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './utils/serviceWorker';
import * as Sentry from '@sentry/react';

import { icons } from './utils/icons';

import { Provider } from 'react-redux';
import store from './utils/store';

React.icons = icons;

Sentry.init({
  dsn:
    'https://913af82e13e948c2b7379b1fd328b513@o4504905707552768.ingest.sentry.io/4505160875376640',
  integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
