import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FORGOT_SUCCESS,
  FORGOT_FAIL,
  LOGIN_AS,
  SET_LANG,
  SET_JWT_TOKEN,
} from '../../actions/auth/types';

const token = JSON.parse(localStorage.getItem('tc.token'));

const initialState = { isLoggedIn: false, token: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token: payload.token,
        user: payload.user,
        lang: payload.user._locale,
      };
    case LOGIN_AS:
      return {
        ...state,
        isLoggedIn: true,
        token: payload.token,
        user: payload.user || payload.data,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
      };
    case FORGOT_FAIL:
      return {
        ...state,
      };
    case SET_LANG:
      console.log('payload 2', payload);
      return {
        ...state,
        lang: payload,
      };
    case SET_JWT_TOKEN:
      return {
        ...state,
        jwtToken: payload,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
