import {
  SET_SIDEBARSHOW,
  SET_SETTINGS,
  SET_CLIENTSITE,
  ADD_NOTIFICATION,
} from '../../actions/general/types';

const initialState = { sidebarShow: true, settings: {}, notification: {} };

export default function (state = initialState, action) {
  const { type, sidebarShow, payload } = action;

  switch (type) {
    case SET_SIDEBARSHOW:
      return { ...state, sidebarShow: sidebarShow };

    case SET_SETTINGS:
      return { ...state, settings: payload };

    case SET_CLIENTSITE:
      return { ...state, clientSite: payload };

    case ADD_NOTIFICATION:
      state.notification = {};
      return { ...state, notification: payload};

    default:
      return state;
  }
}
