import { SET_ERRORS } from '../../actions/errors/types';

const initialState = { errors: [] };

export default function (state = initialState, action) {
  const { type, errors } = action;

  switch (type) {
    case SET_ERRORS:
      return { ...state, errors: errors };

    default:
      return state;
  }
}
