import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CacheBuster from 'react-cache-buster';
import { version } from '../../package.json';
import Loading from '../containers/common/loading/Loading';
import '../i18n';

import useCurrentSite from 'hooks/useCurrentSite';
import HelmetData from '../components/HelmetData';
import * as Sentry from '@sentry/react';
import sentryBoundaryFallback from 'utils/sentryBoundaryFallback';

// Containers
const TheLayout = React.lazy(() => import('../containers/main/TheLayout'));

// Pages
const Login = React.lazy(() => import('../pages/Login'));
const LoginBCF = React.lazy(() => import('../pages/bcf/Login'));
const LoginDYNO = React.lazy(() => import('../pages/dyno/Login'));
const Register = React.lazy(() => import('../pages/Register'));
const RegisterBCF = React.lazy(() => import('../pages/bcf/Register'));
const RegisterDYNO = React.lazy(() => import('../pages/dyno/Register'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const ForgotPasswordBCF = React.lazy(() =>
  import('../pages/bcf/ForgotPassword')
);
const ForgotPasswordDYNO = React.lazy(() =>
  import('../pages/dyno/ForgotPassword')
);
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const ResetPasswordBCF = React.lazy(() => import('../pages/bcf/ResetPassword'));
const ResetPasswordDYNO = React.lazy(() =>
  import('../pages/dyno/ResetPassword')
);
const ActivateAccount = React.lazy(() => import('../pages/ActivateAccount'));
const ActivateAccountBCF = React.lazy(() =>
  import('../pages/bcf/ActivateAccount')
);
const ActivateAccountDYNO = React.lazy(() =>
  import('../pages/dyno/ActivateAccount')
);

const themes = [
  React.lazy(() => import('components/themes/Instance1Theme')),
  React.lazy(() => import('components/themes/Instance2Theme')),
  React.lazy(() => import('components/themes/Instance3Theme')),
];

const App = () => {
  const dispatch = useDispatch();
  const currentInstance = useCurrentSite();
  const currentPath = window.location.host;
  const isProduction = process.env.NODE_ENV === 'production';

  const CurrentTheme = currentInstance?.id ? themes[currentInstance.id - 1] : themes[0];

  useEffect(() => {
    if (currentInstance?.id) {
      dispatch({ type: 'SET_CLIENTSITE', payload: currentInstance });
    }
  }, [currentPath, dispatch, currentInstance]);

  useEffect(() => {
    const settings = [
      {
        hn:
          process.env.NODE_ENV === 'development'
            ? 'localhost'
            : 'tc-performance.com',
        gtmKey: 'GTM-W6F7FJL',
      },
      {
        hn:
          process.env.NODE_ENV === 'development'
            ? 'bcf.local'
            : 'best-chiptuningfiles.com',
        gtmKey: 'GTM-WG4P45T',
      },
      {
        hn:
          process.env.NODE_ENV === 'development'
            ? 'dyno.local'
            : 'dyno-fileservice.com',
        gtmKey: 'GTM-KXDSHDD',
      },
    ];

    const gtm = (w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    };
    if (currentInstance) {
      // Check if the GTM script is already there
      const existingScriptTags = Array.from(
        document.getElementsByTagName('script')
      );
      const gtmScriptsExist =
        existingScriptTags
          ?.map(scr =>
            scr.outerHTML.split(' ').find(el => el.startsWith('src='))
          )
          ?.filter(el => el?.includes('googletagmanager.com/gtm.js')).length >
        0;
      const curr = settings.find(s => currentInstance.hn === s.hn);

      // If we have a curr(ent) instance and there are no scripts for GTM then go ahead
      if (curr && !gtmScriptsExist)
        gtm(window, document, 'script', 'dataLayer', curr.gtmKey);
    }
  }, [currentInstance]);

  return (
    <>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={
          <Loading loadingtext="Refreshing the site. Please wait... " />
        } //If not pass, nothing appears at the time of new version check.
      >
        <Router>
          <HelmetData />
          <Suspense fallback={<Loading loadingtext="Please wait..." />}>
            <CurrentTheme />
            <Sentry.ErrorBoundary fallback={sentryBoundaryFallback}>
              <Switch>
                <Route path="/forgot-password" name="ForgotPassword">
                  {currentInstance?.key === 'fs_tc' && <ForgotPassword />}
                  {currentInstance?.key === 'fs_bcf' && <ForgotPasswordBCF />}
                  {currentInstance?.key === 'fs_dyno' && <ForgotPasswordDYNO />}
                </Route>
                <Route path="/reset-password" name="ResetPassword">
                  {currentInstance?.key === 'fs_tc' && <ResetPassword />}
                  {currentInstance?.key === 'fs_bcf' && <ResetPasswordBCF />}
                  {currentInstance?.key === 'fs_dyno' && <ResetPasswordDYNO />}
                </Route>
                <Route path="/register" name="Register">
                  {currentInstance?.key === 'fs_tc' && <Register />}
                  {currentInstance?.key === 'fs_bcf' && <RegisterBCF />}
                  {currentInstance?.key === 'fs_dyno' && <RegisterDYNO />}
                </Route>
                <Route path="/login" name="Login">
                  {currentInstance?.key === 'fs_tc' && <Login />}
                  {currentInstance?.key === 'fs_bcf' && <LoginBCF />}
                  {currentInstance?.key === 'fs_dyno' && <LoginDYNO />}
                </Route>
                <Route path="/account-activate/:hash" name="ActivateAccount">
                  {currentInstance?.key === 'fs_tc' && <ActivateAccount />}
                  {currentInstance?.key === 'fs_bcf' && <ActivateAccountBCF />}
                  {currentInstance?.key === 'fs_dyno' && <ActivateAccountDYNO />}
                </Route>
                <Route
                  path="/"
                  name="Home"
                  render={props => <TheLayout {...props} />}
                />
              </Switch>
            </Sentry.ErrorBoundary>
          </Suspense>
        </Router>
      </CacheBuster>
    </>
  );
};

export default App;
