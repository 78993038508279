export const types = {
  ADD_ST_EVENT: 'ADD_ST_EVENT',
  ADD_FS_EVENT: 'ADD_FS_EVENT',
  EXPIRED_FS_EVENT: 'EXPIRED_FS_EVENT',
  ADD_ORDER_EVENT: 'ADD_ORDER_EVENT',
  ADD_STMSG_EVENT: 'ADD_STMSG_EVENT',
  ADD_REMARK_EVENT: 'ADD_REMARK_EVENT',
  REMOVE_ST_EVENT: 'REMOVE_ST_EVENT',
  REMOVE_FS_EVENT: 'REMOVE_FS_EVENT',
  REMOVE_ORDER_EVENT: 'REMOVE_ORDER_EVENT',
  REMOVE_STMSG_EVENT: 'REMOVE_STMSG_EVENT',
  REMOVE_REMARK_EVENT: 'REMOVE_REMARK_EVENT',
  UPDATE_ST_EVENT: 'UPDATE_ST_EVENT',
  UPDATE_FS_EVENT: 'UPDATE_FS_EVENT',
  UPDATE_STMSG_EVENT: 'UPDATE_STMSG_EVENT',
  UPDATE_ORDER_EVENT: 'UPDATE_ORDER_EVENT',
  UPDATE_REMARK_EVENT: 'UPDATE_REMARK_EVENT',
  COUNT_EVENTS: 'COUNT_EVENTS',
  COUNT_ST_EVENTS: 'COUNT_ST_EVENTS',
  COUNT_FS_EVENTS: 'COUNT_FS_EVENTS',
  COUNT_ORDER_EVENTS: 'COUNT_ORDER_EVENTS',
};
