export const API_URL = 'https://api-v2.tc-performance.com';

export const MERCURE_URL = 'https://mercure.tc-performance.com/.well-known/mercure';

export const REACT_APP_URL = 'https://file-service.tc-performance.com';

export const SITES = [
  {
    hn: 'tc-performance.com',
    key: 'fs_tc',
    reCAPTCHAPublicKey: '6LeE5hQpAAAAAG7ZC16oj4oUOSk1ruXGtfhRZrKg',
    id: 1,
  },
  {
    hn: 'best-chiptuningfiles.com',
    key: 'fs_bcf',
    reCAPTCHAPublicKey: '6LeE5hQpAAAAAG7ZC16oj4oUOSk1ruXGtfhRZrKg',
    id: 2,
  },
];


// Firebase config
export const REACT_APP_API_KEY = 'AIzaSyCx-1bxtPANfXj77wajgTMFqA_W3ntwuHE';
export const REACT_APP_AUTH_DOMAIN = 'tc-performance.firebaseapp.com';
export const REACT_APP_DATABASE_URL = 'https://project-id.firebaseio.com';
export const REACT_APP_PROJECT_ID = 'tc-performance';
export const REACT_APP_STORAGE_BUCKET = 'tc-performance.appspot.com';
export const REACT_APP_MESSAGING_SENDER_ID = '75738269042';
export const REACT_APP_APP_ID = '1:75738269042:web:400b3e5a18d1e5486e09eb';
export const REACT_APP_MEASUREMENT_ID = 'G-MYTH6SGFWF';
export const REACT_APP_VAPID_KEY = 'BNmvrZ-SptxiUeWlXnHWktPrUzK-V7XWKBNSZHRwD5x_wVIuNlL2NRaIsrSwkxBVCQx0BX_WO5p1Gwr4K8TGuTI';
