export const types = {
  RESET_FORM: 'RESET_FORM',
  SELECT_DEVICE_TYPE: 'SELECT_DEVICE_TYPE',
  SELECT_TARGET: 'SELECT_TARGET',
  ADD_BRAND: 'ADD_BRAND',
  CLEAR_BRAND: 'CLEAR_BRAND',
  ADD_MODEL: 'ADD_MODEL',
  CLEAR_MODEL: 'CLEAR_MODEL',
  ADD_GENERATION: 'ADD_GENERATION',
  CLEAR_GENERATION: 'CLEAR_GENERATION',
  ADD_ENGINE: 'ADD_ENGINE',
  CLEAR_ENGINE: 'CLEAR_ENGINE',
  ADD_TCU: 'ADD_TCU',
  CLEAR_TCU: 'CLEAR_TCU',
  ADD_ECU: 'ADD_ECU',
  CLEAR_ECU: 'CLEAR_ECU',
  ADD_HP: 'ADD_HP',
  ADD_KW: 'ADD_KW',
  ADD_YEAR: 'ADD_YEAR',
  ADD_GEARBOX: 'ADD_GEARBOX',
  CLEAR_GEARBOX: 'CLEAR_GEARBOX',
  ADD_LICENSE_PLATE: 'ADD_LICENSE_PLATE',
  ADD_VIN: 'ADD_VIN',
  ADD_OCTANE_RATING: 'ADD_OCTANE_RATING',
  ADD_HARDWARE_NUMBER: 'ADD_HARDWARE_NUMBER',
  ADD_SOFTWARE_NUMBER: 'ADD_SOFTWARE_NUMBER',
  ADD_READ_METHOD: 'ADD_READ_METHOD',
  CLEAR_READ_METHOD: 'CLEAR_READ_METHOD',
  ADD_TUNING_TYPE: 'ADD_TUNING_TYPE',
  CLEAR_TUNING_TYPE: 'CLEAR_TUNING_TYPE',
  ADD_TUNING_OPTION: 'ADD_TUNING_OPTION',
  CLEAR_TUNING_OPTION: 'CLEAR_TUNING_OPTION',
  ADD_TIMEFRAME: 'ADD_TIMEFRAME',
  SELECT_ONDYNO: 'SELECT_ONDYNO',
  ADD_ADDITIONAL_EMAIL: 'ADD_ADDITIONAL_EMAIL',
  ADD_ADDITIONAL_COMMENT: 'ADD_ADDITIONAL_COMMENT',
  ADD_CAR_MILEAGE: 'ADD_CAR_MILEAGE',
  ADD_CUSTOM_DATA: 'ADD_CUSTOM_DATA',
};
