import {
  DEVICE_TYPE,
  TOKENS_SET,
  DEVICE_SELECTED,
  TOKENS_SELECTED,
  CLEAR_CHECKOUT,
  PAYMENT_METHODS,
  UPDATE_PRICE,
} from '../../actions/checkout/types';

const initialState = {
  deviceType: null,
  tokensSet: null,
  orderDetails: {
    device: null,
    tokensQuantity: null,
    finalPrice: null,
    finalPriceNet: null,
    taxValue: null,
    save: null,
  },
  deviceSelected: false,
  tokensSelected: false,
  paymentMethods: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DEVICE_TYPE: {
      const device =
        payload === 'token_slave'
          ? 'Slave'
          : payload === 'token_master'
          ? 'Master'
          : 'EVC';
      return {
        ...state,
        deviceType: payload,
        orderDetails: {
          ...state.orderDetails,
          device,
        },
      };
    }
    case TOKENS_SET: {
      return {
        ...state,
        tokensSet: payload.id,
        orderDetails: {
          ...state.orderDetails,
          tokensQuantity: payload.quantity,
          finalPrice: payload.finalPrice,
          finalPriceNet: payload.finalPriceNet,
          taxValue: payload.taxValue,
          save: payload.save,
          isReverseCharge: payload.isReverseCharge,
        },
      };
    }
    case DEVICE_SELECTED:
      return {
        ...state,
        deviceSelected: payload,
      };
    case TOKENS_SELECTED:
      return {
        ...state,
        tokensSelected: payload,
      };
    case CLEAR_CHECKOUT:
      return initialState;
    case PAYMENT_METHODS: {
      return { ...state, paymentMethods: payload };
    }
    case UPDATE_PRICE: {
      return { ...state, orderDetails: { ...state.orderDetails, ...payload } };
    }
    default:
      return state;
  }
}
