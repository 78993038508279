import React from 'react';
import { CRow, CCol, CSpinner } from '@coreui/react';

import useCurrentSite from 'hooks/useCurrentSite';
import { siteClasses } from 'utils/siteClasses';

const Loading = ({ loadingtext = 'Loading...', size = '' }) => {
  const currentInstance = useCurrentSite();
  const color = `${siteClasses?.[currentInstance?.key]}-brand`;

  return (
    <CRow>
      <CCol className="d-flex flex-column justify-content-center align-items-center p-3">
        <CSpinner
          color={color}
          className="mb-2 mx-2"
          variant="grow"
          size={size}
        />
        <div className="font-xs mt-2">{loadingtext}</div>
      </CCol>
    </CRow>
  );
};

export default Loading;
