import { useState, useEffect } from 'react';
import { SITES } from '../utils/config';

const initInstance = {
  hn: null,
  key: null,
  reCAPTCHAPublicKey: null,
  id: null,
};

const useCurrentSite = () => {
  const [hn, setHostname] = useState(document.location.hostname);

  useEffect(() => {
    document.addEventListener('readystatechange', event => {
      const ready =
        document.readyState === 'interactive' ||
        document.readyState === 'complete';
      if (ready) setHostname(document.location.hostname);
    });

    return () => document.removeEventListener('readystatechange');
  }, []);

  const currentInstance = hn ? SITES.find(site => hn.includes(site.hn)) : initInstance;

  return currentInstance;
};

export default useCurrentSite;
